import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { loadMoreDataWhenScrolled, parseHeaderForLinks } from 'react-jhipster';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ILoanProfile, defaultValue } from 'app/shared/model/loan-profile.model';

const initialState: EntityState<ILoanProfile> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: [],
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  selectedStatus: '',
};

const TermLoanScheduleUrl = `${SERVER_API_URL}core-banking/flender/term-loan/term-loan-schedule`;
const BreakMonthsUrl = `${SERVER_API_URL}core-banking/flender/term-loan/applyPaymentBreak`;
const dueDateEditUrl = `${SERVER_API_URL}core-banking/flender/term-loan/changeDueDate`;
const installmentUrl = `${SERVER_API_URL}core-banking/flender/term-loan/changeInstalmentAmount`;

export const getTermloanScheduleEntity = createAsyncThunk(
  'termloanSchedule/fetch_entity',
  async (loanId: any) => {
    const requestUrl = `${TermLoanScheduleUrl}?loanId=${loanId}`;
    const response = await axios.get<ILoanProfile>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const addBreakMonths = createAsyncThunk(
  'termloanSchedule/addBreakMonths',
  async ({ loanId, instalment_number, breakPeriod }: ILoanProfile) => {
    try {
      const requestUrl = BreakMonthsUrl;  
      const response = await axios.put<ILoanProfile[]>(requestUrl, {
        loan_id: loanId,
        instalment_number,  
        break_period: breakPeriod,
      });
      return response.data;
    } catch (error) {
      throw serializeAxiosError(error);
    }
  },
);


export const editDueDate = createAsyncThunk(
  'termloanSchedule/edit_due_date',
  async ({ loanId, instalmentNumber, newDueDate }: ILoanProfile) => {
    try {
      const requestUrl = `${dueDateEditUrl}`;
      const response = await axios.put<ILoanProfile[]>(requestUrl, {
        loan_id: loanId,
        instalment_number: instalmentNumber,
        new_due_date: newDueDate,
      });
      return response.data;
    } catch (error) {
      throw serializeAxiosError(error);
    }
  },
);

export const editInstallmentAmount = createAsyncThunk(
  'termloanSchedule/edit_installment_amount',
  async (payload: { data: { loan_id: string; instalment_number: number; new_instalment_amount: number } }) => {
    try {
      const requestUrl = `${installmentUrl}`;
      const response = await axios.put<ILoanProfile[]>(requestUrl, payload.data);
      return response.data;
    } catch (error) {
      throw serializeAxiosError(error);
    }
  },
);

export const TermloanScheduleSlice = createEntitySlice({
  name: 'termloanSchedule',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getTermloanScheduleEntity.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getTermloanScheduleEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(getTermloanScheduleEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(addBreakMonths.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(addBreakMonths.fulfilled, (state, action) => {
        state.loading = false;
        state.breakMonths = action.payload;
      })
      .addCase(addBreakMonths.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(editDueDate.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })

      .addCase(editDueDate.fulfilled, (state, action) => {
        state.loading = false;
        state.editDueDate = action.payload;
      })
      .addCase(editDueDate.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(editInstallmentAmount.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })

      .addCase(editInstallmentAmount.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset } = TermloanScheduleSlice.actions;

export default TermloanScheduleSlice.reducer;
