import React, { useState, useEffect } from 'react';
import { Button, Card, CardHeader, Input, Label, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getTermloanListEntity } from '../term-loan-profile/term-loan-profile.reducer';
import { getBorrowerIdList } from '../loan-repayment/loan-repayment.reducer';
import { termLoanBorrowerList } from '../term-loan-profile/term-loan-profile.reducer';
import { getTermloanScheduleEntity, addBreakMonths, editDueDate, editInstallmentAmount } from './term-loan-schedule.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";

export const TermLoanSchedule = () => {
  const dispatch = useAppDispatch();
  const [borrowerName, setBorrowerName] = useState<string>('');
  const [borrowerId, setBorrowerId] = useState<string>('');
  const [loanId, setLoanId] = useState<string>('');
  const [showAddTermBreak, setShowAddTermBreak] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [viewClicked, setViewClicked] = useState(false);
  const [breakInstalment, setBreakInstalment] = useState<number>(0);
  const [breakPeriod, setBreakPeriod] = useState<number>(0);
  const [editingDueDate, setEditingDueDate] = useState<string | null>(null);
  const [newDueDate, setNewDueDate] = useState<string>('');
  const [isDueDateEditEnabled, setIsDueDateEditEnabled] = useState(false);
  const [isInstallmentAmountEditEnabled, setIsInstallmentAmountEditEnabled] = useState(false);

  const [newInstallmentAmount, setNewInstallmentAmount] = useState<string>('');
  const [editInstallment, setEditInstallment] = useState(false);

  const borrowerNameList = useAppSelector(state => state.termLoanProfile?.termLoanBorrowers);
  const borrowerIdList = useAppSelector(state => state.loanRepayment?.borrowerIdList);
  const termLoanIdList = useAppSelector(state => state.termLoanProfile?.loanIdList);
  const termLoanSchedule = useAppSelector(state => state.termLoanSchedule?.entity);

  useEffect(() => {
    dispatch(termLoanBorrowerList({}));
  }, [dispatch]);

  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);
    setBorrowerId('');
    setLoanId('');
    setErrorMessage(null);
    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
      setErrorMessage('Error fetching borrower ID list');
    }
  };

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);
    setLoanId('');
    setErrorMessage(null);

    try {
      await dispatch(getTermloanListEntity(selectedBorrowerId));
    } catch (error) {
      setErrorMessage('Error fetching loan ID list');
    }
  };

  const handleLoanIdChange = event => {
    const selectedLoanId = event.target.value;
    setLoanId(selectedLoanId);
    setErrorMessage(null);
  };

  const viewLoanSchedule = async () => {
    if (loanId) {
      setLoading(true);
      try {
        await dispatch(getTermloanScheduleEntity(loanId));
      } catch (error) {
        setErrorMessage('Error fetching loan schedule');
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage('No Loan ID selected');
    }
  };

  const handleViewLoanScheduleClick = () => {
    viewLoanSchedule();
    setViewClicked(true);
  };

  const handleAddTermBreakToggle = () => {
    setShowAddTermBreak(!showAddTermBreak);
  };

  const handleAddTermBreak = async () => {
    if (!loanId || !breakInstalment || !breakPeriod) {
      setErrorMessage('Please fill in all the required fields.');
      return;
    }
    try {
      await dispatch(addBreakMonths({ loanId, instalment_number: breakInstalment, breakPeriod }));
      viewLoanSchedule();
    } catch (error) {
      setErrorMessage('Error adding break months');
    }
  };


  const handleDueDateChange = (event, instalment) => {
    const selectedDate = event.target.value;
    if (selectedDate) {
      setNewDueDate(selectedDate);
      setEditingDueDate(instalment);
    } else {
      setErrorMessage('Please enter a valid date');
    }
  };

  const handleSaveDueDate = async (loanId, instalmentNumber, currentDueDate) => {
    if (!newDueDate) {
      setErrorMessage('Please enter a valid due date.');
      return;
    }

    const selectedDate = new Date(newDueDate);
    const currentDate = new Date(currentDueDate);

    if (selectedDate <= currentDate) {
      setErrorMessage('New due date cannot be older than the current due date.');
      alert('New due date cannot be older than the current due date.');

      return;
    }

    const formattedNewDueDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 12, 0, 0));

    const apiFormattedNewDueDate = formattedNewDueDate.toISOString().slice(0, 19);

    try {
      await dispatch(editDueDate({ loanId, instalmentNumber, newDueDate: apiFormattedNewDueDate }));
      viewLoanSchedule();
      setEditingDueDate(null);
      setErrorMessage(null);
    } catch (error) {
      console.error(error);
      setErrorMessage('Error updating due date');
    }
    setIsDueDateEditEnabled(false);
  };




  const handleEditDueDateToggle = () => {
    if (isDueDateEditEnabled) {
      setEditingDueDate(null);
      setNewDueDate('');
    }
    setIsDueDateEditEnabled(!isDueDateEditEnabled);
  };

  const handleCancelEdit = () => {
    setEditingDueDate(null);
    setNewDueDate('');
  };

  const handleEditInstallmentAmount = () => {
    if (!editInstallment) {
      setEditInstallment(true);
    }
  };

  const handleCancelEditInstallment = () => {
    setEditInstallment(false);
    setNewInstallmentAmount('');
    setEditingTotalExpected(null);
  };



  const [newTotalExpected, setNewTotalExpected] = useState<string>('');
  const [editingTotalExpected, setEditingTotalExpected] = useState<number | null>(null);

  const handleTotalExpectedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTotalExpected(event.target.value);
  };



  const handleSaveTotalExpected = async (loanId: string, instalmentNumber: number, newTotalExpected: string) => {
    if (!newTotalExpected) {
      setErrorMessage('Please enter a valid installment amount.');
      return;
    }

    const payload = {
      data: {
        loan_id: loanId,
        instalment_number: instalmentNumber,
        new_instalment_amount: parseFloat(newTotalExpected),
      },
    };

    try {
      await dispatch(editInstallmentAmount(payload));

      setEditInstallment(false);
      setNewInstallmentAmount('');
      setEditingTotalExpected(null);
      viewLoanSchedule();
    } catch (error) {
      setErrorMessage('Error updating installment amount');
    }
  };

  return (
    <div className="container-fluid">
      <h2 id="general-ledger-heading" data-cy="GeneralLedgerHeading">
        Term Loan Schedule
      </h2>
      <div className="mb-3">
        <div className="d-flex mb-5 mt-5 align-items-center dropDownContainer">
          <span className="me-2 ms-1 mt-1 text-dark">
            <strong>Select Borrower Name: </strong>
          </span>
          <select value={borrowerName} onChange={handleBorrowerName}>
            <option>Select Borrower</option>
            {borrowerNameList?.map((borrower, i) => (
              <option key={i} value={borrower.borrowerBusinessName}>
                {borrower.borrowerBusinessName}
              </option>
            ))}
          </select>

          <span className="me-2 ms-5 mt-1 text-dark">
            <strong>Select Borrower ID: </strong>
          </span>
          <select value={borrowerId} onChange={handleBorrowerId} disabled={!borrowerName}>
            <option>Select Borrower ID</option>
            {borrowerIdList?.map((borrower_Id, index) => (
              <option key={index} value={borrower_Id}>
                {borrower_Id}
              </option>
            ))}
          </select>

          <span className="me-2 ms-5 mt-1 text-dark">
            <strong>Select Loan ID: </strong>
          </span>
          <select value={loanId} onChange={handleLoanIdChange} className="me-3" disabled={!borrowerId}>
            <option value="">Select Loan</option>
            {termLoanIdList?.map((loanId, i) => (
              <option key={i} value={loanId}>
                {loanId}
              </option>
            ))}
          </select>
          <Button className="customButton" onClick={handleViewLoanScheduleClick}>
            View
          </Button>
        </div>
      </div>

      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      {!showAddTermBreak && viewClicked && loanId.length > 0 && (
        <div className="d-flex">
          <>
            <Button className="customButton mb-4" onClick={handleAddTermBreakToggle}>
              Add Payment Break
            </Button>
            <Button className="customButton ms-3 mb-4" onClick={handleEditDueDateToggle}>
              {isDueDateEditEnabled ? 'Cancel Editing' : 'Edit Due Date'}
            </Button>

            <Button
              className="customButton ms-3 mb-4"
              onClick={editInstallment ? handleCancelEditInstallment : handleEditInstallmentAmount}
            >
              {editInstallment ? 'Cancel Editing' : 'Edit Installment Amount'}
            </Button>
          </>
        </div>
      )}

      {showAddTermBreak && termLoanSchedule && termLoanSchedule.length > 0 && (
        <Card className="mt-3">
          <CardHeader>
            <div className="d-flex justify-content-between">
              <div>Add Term Break</div>
              <div onClick={handleAddTermBreakToggle} style={{ cursor: 'pointer' }}>
                X
              </div>
            </div>
          </CardHeader>
          <div className="col-md-9 d-flex mt-4">
            <div className="col-md-3">
              <Label for="loanIdInput">
                <strong>Term Loan Id</strong>
              </Label>
              <Input id="loanIdInput" type="text" value={loanId} readOnly />
            </div>
            <div className="col-md-3 ms-3">
              <Label for="Installment">
                <strong>Installment :</strong>
              </Label>
              <select id="Installment" value={breakInstalment} onChange={e => setBreakInstalment(Number(e.target.value))}>
                <option value="">Select Installment</option>
                {termLoanSchedule?.map((termLoan, i) => (
                  <option key={i} value={termLoan.instalment}>
                    {termLoan.instalment}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3 ms-3">
              <Label for="breakMonths">
                <strong>Break Months :</strong>
              </Label>
              <select id="breakMonths" value={breakPeriod || ''} onChange={e => setBreakPeriod(Number(e.target.value))}>
                <option value="">Select Break Months</option>
                {(() => {
                  const options = [];
                  for (let month = 1; month <= 12; month++) {
                    options.push(
                      <option key={month} value={month}>
                        {month}
                      </option>,
                    );
                  }
                  return options;
                })()}
              </select>
            </div>
            <div className="col-md-3 ms-3 mt-3">
              <Button onClick={handleAddTermBreak} className="customButton mt-3">
                Add Term Break
              </Button>
            </div>
          </div>
        </Card>
      )}
      {viewClicked && termLoanSchedule && termLoanSchedule.length > 0 && (
        <div className="table-responsive mainContainer">
          <Table responsive className="table-striped table-bordered">
            <thead>
              <tr>
                <th>Id</th>
                <th>Loan Id</th>
                <th>Installment</th>
                <th>Due Date</th>
                <th>Principal Balance Opening</th>
                <th>Principal Expected</th>
                <th>Principal Balance Closing</th>
                <th>Interest Expected</th>
                <th>Interest Days</th>
                <th>Billing Days Adjustment</th>
                <th>Fees Expected</th>
                <th>Penalty Expected</th>
                <th>Total Expected</th>
                <th>Principal Paid</th>
                <th>Interest Paid</th>
                <th>Fee Paid</th>
                <th>Penalty Paid</th>
                <th>Total Paid</th>
                <th>Paid Date And Time</th>
                <th>Principal Due</th>
                <th>Interest Due</th>
                <th>Fee Due</th>
                <th>Penalty Due</th>
                <th>Total Due</th>
                <th>State</th>
                <th>Type of Payment</th>
                <th>Ad Hoc Fee</th>
                <th>Legal Collection Fee</th>
                <th>Exit Fee</th>
              </tr>
            </thead>
            <tbody>
              {termLoanSchedule.map((termLoan, index) => (
                <tr key={index}>
                  <td>{termLoan.id ? termLoan.id : 0}</td>
                  <td>{termLoan.loanId ? termLoan.loanId : 0}</td>
                  <td>{termLoan.instalment ? termLoan.instalment : 0}</td>
                  <td>
                    {editingDueDate === termLoan.instalment ? (
                      <>
                        <input type="date"
                          value={moment(newDueDate).format("YYYY-MM-DD")}

                          onChange={e => handleDueDateChange(e, termLoan.instalment)} />
                        <Button
                          className="inner-table-custom-button"
                          onClick={() => handleSaveDueDate(termLoan.loanId, termLoan.instalment, termLoan.dueDate)}
                          disabled={!newDueDate}
                        >
                          <FontAwesomeIcon icon="save" />
                        </Button>

                        <Button className="inner-table-custom-button" onClick={handleCancelEdit}>
                          <strong>X</strong>
                        </Button>
                      </>
                    ) : (
                      <>
                        {termLoan.dueDate ? moment(termLoan.dueDate).format("YYYY-MM-DD") : '00/00/0000'}
                        {isDueDateEditEnabled && (
                          <Button
                            className="inner-table-custom-button"
                            onClick={() => {
                              setEditingDueDate(termLoan.instalment);
                              setNewDueDate(termLoan.dueDate);
                            }}
                          >
                            <FontAwesomeIcon icon="pencil-alt" />
                          </Button>
                        )}
                      </>
                    )}
                  </td>

                  <td>{termLoan.principalBalanceOpening ? termLoan.principalBalanceOpening : 0}</td>
                  <td>{termLoan.principalExpected ? termLoan.principalExpected : 0}</td>
                  <td>{termLoan.principalBalanceClosing ? termLoan.principalBalanceClosing : 0}</td>
                  <td>{termLoan.interestExpected ? termLoan.interestExpected : 0}</td>
                  <td>{termLoan.interestDays ? termLoan.interestDays : 0}</td>
                  <td>{termLoan.billingDaysAdjustment ? termLoan.billingDaysAdjustment : 0}</td>
                  <td>{termLoan.feesExpected ? termLoan.feesExpected : 0}</td>
                  <td>{termLoan.penaltyExpected ? termLoan.penaltyExpected : 0}</td>
                  {/* <td>{termLoan.totalExpected ? termLoan.totalExpected : 0}</td> */}

                  <td>
                    {editingTotalExpected === termLoan.instalment ? (
                      <>
                        <input
                          type="number"
                          value={newTotalExpected}
                          onChange={e => setNewTotalExpected(e.target.value)}
                          placeholder="Total Expected"
                        />
                        <Button
                          className="inner-table-custom-button"
                          onClick={() => handleSaveTotalExpected(termLoan.loanId, termLoan.instalment, newTotalExpected)}
                          disabled={!newTotalExpected}
                        >
                          <FontAwesomeIcon icon="save" />
                        </Button>
                        <Button className="inner-table-custom-button" onClick={() => setEditingTotalExpected(null)}>
                          <strong>X</strong>
                        </Button>
                      </>
                    ) : (
                      <>
                        {termLoan.totalExpected !== undefined ? termLoan.totalExpected : 'N/A'}
                        {editInstallment && (
                          <Button
                            className="inner-table-custom-button"
                            onClick={() => {
                              setEditingTotalExpected(termLoan.instalment);
                              setNewTotalExpected(termLoan.totalExpected);
                            }}
                          >
                            <FontAwesomeIcon icon="pencil-alt" />
                          </Button>
                        )}
                      </>
                    )}
                  </td>

                  <td>{termLoan.principalPaid ? termLoan.principalPaid : 0}</td>
                  <td>{termLoan.interestPaid ? termLoan.interestPaid : 0}</td>
                  <td>{termLoan.feePaid ? termLoan.feePaid : 0}</td>
                  <td>{termLoan.penaltyPaid ? termLoan.penaltyPaid : 0}</td>
                  <td>{termLoan.totalPaid ? termLoan.totalPaid : 0}</td>
                  <td>{termLoan.paidDateTime ? termLoan.paidDateTime : '00/00/0000'}</td>
                  <td>{termLoan.principalDue ? termLoan.principalDue : 0}</td>
                  <td>{termLoan.interestDue ? termLoan.interestDue : 0}</td>
                  <td>{termLoan.feeDue ? termLoan.feeDue : 0}</td>
                  <td>{termLoan.penaltyDue ? termLoan.penaltyDue : 0}</td>
                  <td>{termLoan.totalDue ? termLoan.totalDue : 0}</td>
                  <td>{termLoan.state ? termLoan.state : 0}</td>
                  <td>{termLoan.typeOfPayment ? termLoan.typeOfPayment : 0}</td>
                  <td>{termLoan.adHocFee ? termLoan.adHocFee : 0}</td>
                  <td>{termLoan.legalCollectionFee ? termLoan.legalCollectionFee : 0}</td>
                  <td>{termLoan.exitFee ? termLoan.exitFee : 0}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default TermLoanSchedule;
