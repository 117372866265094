import './home.scss';
import React, { useCallback, useState } from 'react';
import { Row, Col, Button, NavItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GeneralLedger from 'app/entities/general-ledger/general-ledger';
import LoanRepayment from 'app/entities/loan-repayment/loan-repayment';
import LoanProfile from 'app/entities/loan-profile/loan-profile';
import LoanFunder from 'app/entities/loan-funder/loan-funder';
import LoanStatus from 'app/entities/loan-status/loan-status';
import Reporting from 'app/entities/transaction-posting/transaction-posting';
import Borrower from 'app/entities/borrower/borrower';
import { useAppDispatch } from 'app/config/store';
import { fetchUrl } from './home.reducer';
import TermLoanProfile from 'app/entities/term-loan-profile/term-loan-profile';
import TermLoanSchedule from 'app/entities/term-loan-schedule/term-loan-schedule';
import Loan from 'app/entities/loan/loan';
import LenderInvestment from 'app/entities/lender-investment/lender-investment';

export const Home = () => {
  const [activeMenu, setActiveMenu] = useState('RBLLoan');
  const [selectedContent, setSelectedContent] = useState('GeneralLedger');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const dispatch = useAppDispatch();

  const handleMenuClick = menu => {
    setActiveMenu(menu);
    if (menu === 'TermLoan') {
      setSelectedContent('TermLoanProfile');
    } else if (menu === 'RBLLoan') {
      setSelectedContent('GeneralLedger');
    } else if (menu === 'LenderInvestment') {
      setSelectedContent('LenderInvestment');
    }
  };

  const handleNfUserTokenButtonClick = useCallback(async () => {
    try {
      const action = await dispatch(fetchUrl());
      const response = action.payload;
      window.open(response);
    } catch (error) {
      console.error('Error fetching URL:', error);
    }
  }, [dispatch]);

  const handleContentClick = content => {
    setSelectedContent(content);
  };

  const renderRBLLoanMenu = () => (
    <div className={`flurish-sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
      <div
        onClick={() => handleContentClick('GeneralLedger')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'GeneralLedger' ? 'active' : ''}`}
        title="General Ledger"
      >
        <FontAwesomeIcon icon="file-lines" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">General Ledger</span>
      </div>

      <div
        onClick={() => handleContentClick('RepaymentReports')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'RepaymentReports' ? 'active' : ''}`}
        title="Repayment Reports"
      >
        <FontAwesomeIcon icon="tasks" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Repayment Reports</span>
      </div>

      <div
        onClick={() => handleContentClick('LoanProfile')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'LoanProfile' ? 'active' : ''}`}
        title="Loan Profile"
      >
        <FontAwesomeIcon icon="chalkboard" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Loan Profile</span>
      </div>

      <div
        onClick={() => handleContentClick('LenderProfile')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'LenderProfile' ? 'active' : ''}`}
        title="Lender Profile"
      >
        <FontAwesomeIcon icon="wallet" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Lender Profile</span>
      </div>

      <div
        onClick={() => handleContentClick('LoanStatus')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'LoanStatus' ? 'active' : ''}`}
        title="Loan Status"
      >
        <FontAwesomeIcon icon="bars-progress" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Loan Status</span>
      </div>

      <div
        onClick={() => handleContentClick('LoanReports')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'LoanReports' ? 'active' : ''}`}
        title="Loan Reports"
      >
        <FontAwesomeIcon icon="th-list" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Loan Reports</span>
      </div>

      <div
        onClick={() => handleContentClick('ManualLedgerPosting')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'ManualLedgerPosting' ? 'active' : ''}`}
        title="Manual Ledger Posting"
      >
        <FontAwesomeIcon icon="file-export" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Manual Ledger Posting</span>
      </div>

      <div
        onClick={() => handleContentClick('BorrowerList')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'BorrowerList' ? 'active' : ''}`}
        title="Borrower List"
      >
        <FontAwesomeIcon icon="users-line" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Borrower List</span>
      </div>

      <div onClick={() => handleNfUserTokenButtonClick()} className="nf-token-btn">
        <FontAwesomeIcon icon="sign-in-alt" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">NF User Token Login / Update</span>
      </div>
    </div>
  );

  const renderTermLoanMenu = () => (
    <div className={`flurish-sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
      <div
        onClick={() => handleContentClick('TermLoanProfile')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'TermLoanProfile' ? 'active' : ''}`}
        title="Term Loan Profile"
      >
        <FontAwesomeIcon icon="chalkboard" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Term Loan Profile</span>
      </div>

      <div
        onClick={() => handleContentClick('TermLoanSchedule')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'TermLoanSchedule' ? 'active' : ''}`}
        title="Term Loan Schedule"
      >
        <FontAwesomeIcon icon="tasks" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Term Loan Schedule</span>
      </div>
    </div>
  );

  const renderLenderInvestmentMenu = () => (
    <div className={`flurish-sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
      <div
        onClick={() => handleContentClick('LenderInvestment')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'LenderInvestment' ? 'active' : ''}`}
        title="Lender Investment"
      >
        <FontAwesomeIcon icon="user" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Lender Investment</span>
      </div>
    </div>
  );

  const renderContent = () => {
    if (selectedContent === 'GeneralLedger') return <GeneralLedger />;
    if (selectedContent === 'RepaymentReports') return <LoanRepayment />;
    if (selectedContent === 'LoanProfile') return <LoanProfile />;
    if (selectedContent === 'LenderProfile') return <LoanFunder />;
    if (selectedContent === 'LoanStatus') return <LoanStatus />;
    if (selectedContent === 'LoanReports') return <Loan />;
    if (selectedContent === 'ManualLedgerPosting') return <Reporting />;
    if (selectedContent === 'BorrowerList') return <Borrower />;
    if (selectedContent === 'TermLoanProfile')
      return (
        <div>
          <TermLoanProfile />
        </div>
      );
    if (selectedContent === 'TermLoanSchedule')
      return (
        <div>
          <TermLoanSchedule />
        </div>
      );
    if (selectedContent === 'LenderInvestment')
      return (
        <div>
          <LenderInvestment />
        </div>
      );

    return <div> </div>;
  };

  return (
    <div className="main-area-container">
      <div className="d-flex">
        <div>
          <Button
            className={`customButton ${activeMenu === 'RBLLoan' ? 'rbl-loan-active' : ''}`}
            onClick={() => handleMenuClick('RBLLoan')}
          >
            RBL Loan
          </Button>
        </div>
        <div>
          <Button
            className={`customButton ms-4 ${activeMenu === 'TermLoan' ? 'term-loan-active' : ''}`}
            onClick={() => handleMenuClick('TermLoan')}
          >
            Term Loan
          </Button>
        </div>
        <div>
          <Button
            className={`customButton ms-4 ${activeMenu === 'LenderInvestment' ? 'lender-investment-active' : ''}`}
            onClick={() => handleMenuClick('LenderInvestment')}
          >
            Lender Investment
          </Button>
        </div>
      </div>

      <div className="mt-4">
        <Row>
          <Col md="2" className="flurish-left-sidebar">
            {activeMenu === 'RBLLoan' && renderRBLLoanMenu()}
            {activeMenu === 'TermLoan' && renderTermLoanMenu()}
            {activeMenu === 'LenderInvestment' && renderLenderInvestmentMenu()}
          </Col>
          <Col md="10" className="content-area">
            <div className="p-3">{renderContent()}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
