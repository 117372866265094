export interface ILoanProfile {
  data?: any;
  loanId?: any;
  loan_status?: string | null;
  report_generated_date?: string | null;
  profile_statement_from?: string | null;
  daprofile_statement_tote?: string | null;
  borrower_id?: string | null;
  borrower_name?: string | null;
  net_loan?: number | null;
  origination_fee?: number | null;
  gross_loan?: number | null;
  underwritten_term?: number | null;
  total_repayment?: number | null;
  daily_average?: number | null;
  daily_average_pos_reciepts?: number | null;
  holdback_rate?: number | null;
  total_repayment_done?: number | null;
  pending_repayment?: number | null;
  funder_outstanding_fee?: number | null;
  flender_outstanding_fee?: number | null;
  Outstanding_principle?: number | null;
  breakInstalment?: number | null;
  breakPeriod?: number | null;
  instalmentNumber?: any;
  instalment_number?: any;
  newDueDate?: any;
  instalment?: any;
  newInstallmentAmount?: any;
  new_instalment_amount?: any;
  termLoanBorrowerList?: any;
}

export const defaultValue: Readonly<ILoanProfile> = {};
